.experience-timeline {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Inter', sans-serif;
}

.timeline-item {
  border: 1px solid #000;
  border-radius: 0;
  margin-bottom: -1px;
  background: transparent;
  position: relative; 
}

.timeline-header {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}

.timeline-year {
  font-weight: 500;
  font-size: 1.25rem;
  font-family: 'Space Grotesk', sans-serif;
}

.company {
  font-size: 1.25rem;
  font-weight: 400;
  margin: 0;
}

.title {
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  color: #000;
}

.location {
  font-size: 1rem;
  color: #000;
  margin: 0;
}

.toggle-button {
  background: none;
  border: none;
  font-size: 2rem;
  font-weight: 300;
  color: #000;
  cursor: pointer;
  padding: 0.5rem;
  line-height: 1;
  transition: opacity 0.2s ease;
}

.toggle-button:hover {
  opacity: 0.7;
}

.timeline-details {
  background-color: #5BF58B;
  padding: 1rem 1.5rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 0.5rem;
}

.timeline-details ul {
  margin: 0;
  padding-left: 1.25rem; /* ensures bullets have room */
  list-style-type: disc;
  list-style-position: outside;
  
}

.timeline-details li {
  margin-bottom: 0.75rem;
  font-size: 0.95rem;
  line-height: 1.5;
  color: #444;
  text-align: left;
}

.timeline-details li:last-child {
  margin-bottom: 0;
}

.timeline-item.current {
  background-color: transparent;
} 

@media (max-width: 768px) {
  .timeline-header {
    grid-template-columns: 1fr;
    gap: 0.5rem;
    padding: 1rem;
  }

  .toggle-button {
    align-self: flex-start;
    margin-top: 0.5rem;
  }

  .timeline-details {
    font-size: 0.9rem;
  }
}