.contact-section {
  padding: 1rem 2rem 4rem;
  background-color: #fff;
  text-align: center;
}

.contact-title {
  font-size: 3rem;
  font-weight: 900;
  margin-bottom: 2rem;
  letter-spacing: 0.05em;
}

.contact-form {
  background: white;
  max-width: 700px;
  margin: 0 auto;
  padding: 2rem;
  border: 1px solid #000;
  border-radius: 16px;
  box-shadow: 5px 5px 0 #000;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  flex-wrap: wrap;
}

.input-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.input-group label {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.input-group input,
.input-group textarea {
  border: 1px solid #000;
  border-radius: 10px;
  padding: 0.75rem;
  font-size: 1rem;
  width: 100%;
  resize: vertical;
  box-shadow: 2px 2px 0 #000;
}

.input-group input:disabled,
.input-group textarea:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 0.7;
}

textarea {
  min-height: 150px;
}

.submit-btn {
  padding: 0.75rem 1.5rem;
  background-color: #5BF58B;
  color: #000;
  font-weight: bold;
  font-size: 1rem;
  border: 1px solid #000;
  border-radius: 10px;
  box-shadow: 3px 3px 0 #000;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.submit-btn:hover:not(:disabled) {
  transform: scale(1.03);
}

.submit-btn:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
  opacity: 0.7;
}

.submit-status {
  padding: 1rem;
  border-radius: 8px;
  font-weight: 500;
  margin-bottom: 1rem;
}

.submit-status.success {
  background-color: #e6ffe6;
  color: #006600;
  border: 1px solid #00cc00;
}

.submit-status.error {
  background-color: #ffe6e6;
  color: #cc0000;
  border: 1px solid #ff0000;
}

.thank-you-message {
  background: white;
  max-width: 700px;
  margin: 0 auto;
  padding: 2rem;
  border: 1px solid #000;
  border-radius: 16px;
  box-shadow: 5px 5px 0 #000;
  text-align: center;
}

.thank-you-message h3 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #000;
}

.thank-you-message p {
  font-size: 1.1rem;
  margin-bottom: 2rem;
  color: #444;
} 