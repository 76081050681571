/* src/Views/HomePage.css */
html {
    scroll-behavior: smooth;
}

.cafe-homepage {
    text-align: center;
    padding: 50px;
}

.cafe-homepage h1 {
    font-size: 3rem;
    margin-bottom: 20px;
}

.cafe-homepage p {
    font-size: 1.5rem;
    color: #555;
}

.cafe-color-box {
    width: 100px;
    height: 100px;
    margin: 20px auto;
    transition: background-color 0.5s ease-in-out;
}

.cafe-color-box.cafe-red {
    background-color: red;
}

.cafe-color-box.cafe-blue {
    background-color: blue;
}

.homepage {
    min-height: 100vh;
    background-color: white;
}

.main-content {
    padding-top: 65px; /* Reduced to match exactly the nav height */
}

.experience-section {
    padding: 4rem 0;
    background-color: white;
    scroll-margin-top: 65px; /* This accounts for the fixed navigation */
}

.section-title {
    font-family: 'Inter', sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0 auto 3rem;
    max-width: 1200px;
    padding: 0 1rem;
    text-align: left;
}

.experience-cta {
    margin-top: 3rem;
  text-align: left;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem;
  }
  
  .experience-cta .btn {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;
    border: 1px solid #000;
    border-radius: 10px;
    box-shadow: 3px 3px 0 #000;
    cursor: pointer;
    transition: transform 0.2s ease;
  }
  
  .experience-cta .btn:hover {
    transform: scale(1.03);
  }
  
  .experience-cta .btn.dark {
    background-color: #000;
    color: #fff;
  }

  .chatgpt-banner {
    background-color: #5BF58B;
    color: #000;
    text-align: center;
    padding: 0.75rem 1rem;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 1rem;
    border-bottom: 1px solid #000;
}
  