.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 4rem;
  background-color: white;
  width: 100%;
  position: fixed;
  left: 0;
  z-index: 1000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

.nav-left {
  display: flex;
  align-items: center;
}

.logo {
  font-weight: bold;
  font-size: 2rem;
  margin-right: 2rem;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 2.5rem;
  margin: 0;
  padding: 0;
}

.nav-links a {
  text-decoration: none;
  color: #000;
  font-size: 0.9rem;
  font-weight: 400;
  transition: opacity 0.2s ease;
}

.nav-links a:hover {
  opacity: 0.6;
}

.nav-right {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.social-icon {
  width: 24px;
  height: 24px;
  transition: opacity 0.2s ease;
}

.social-icon:hover {
  opacity: 0.7;
}

.nav-right a {
  margin-left: 1rem;
  font-size: 1.2rem;
} 

.contact-btn {
  padding: 0.5rem 1.25rem;
  font-size: 0.9rem;
  border-radius: 6px;
  font-weight: 600;
  text-decoration: none;
  background-color: #00AEEF;
  color: #fff;
  transition: background-color 0.3s ease;
  white-space: nowrap;
}

.contact-btn:hover {
  background-color: #0095cc;
}

@media (max-width: 768px) {
  .nav {
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 2rem;
  }

  .nav-links {
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  .nav-right {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    gap: 1rem;
    margin-top: 1rem;
  }

  .nav-links,
  .nav-right {
    margin: 0;
    padding: 0;
  }
}
/* Hamburger styles */
.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 24px;
  height: 18px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.hamburger .bar {
  height: 3px;
  background-color: #000;
  border-radius: 1px;
}

/* Hamburger hidden by default */
.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 24px;
  height: 18px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.hamburger .bar {
  height: 3px;
  background-color: #000;
  border-radius: 1px;
}

/* Structure for nav content */
.nav-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* === MOBILE ONLY STYLES === */
@media (max-width: 768px) {
  .hamburger {
    display: flex;
  }

  .nav-content {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    width: 100%;
    margin-top: 1rem;
  }

  .nav-content.open {
    display: flex;
  }

  .nav-links {
    flex-direction: column;
    gap: 1rem;
  }

  .nav-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    width: 100%;
    box-sizing: border-box;
    padding: 0 1rem; /* <-- ensures padding on both sides */
  }

  .contact-btn {
    width: 100%;
    text-align: center;
    box-sizing: border-box;
  }
}


