.portfolio-section {
  padding: 4rem 2rem;
  background-color: #fff;
  text-align: center;
  font-family: 'Inter', sans-serif;
}

.portfolio-categories {
  width: 100%;
  max-width: 1200px;
  background-color: #f9f9f9;
  border-radius: 999px;
  padding: 1.25rem 2rem;
  display: flex; /* switch from inline-flex to flex */
  justify-content: space-between; /* evenly distribute space */
  align-items: center;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
  margin: 0 auto 3rem;
  flex-wrap: wrap;
}

.category-btn {
  background: none;
  border: none;
  font-weight: 500;
  color: #555;
  cursor: pointer;
  font-size: 1.2rem;
  transition: all 0.2s ease;
}

.category-btn.active {
  color: #4BE07A;
  font-weight: 700;
  text-shadow: -0.75px -0.75px 0 #000, 0.75px -0.75px 0 #000, -0.75px 0.75px 0 #000, 0.75px 0.75px 0 #000;
}

.portfolio-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.portfolio-card {
  position: relative;
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.portfolio-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

.image-wrapper {
  position: relative;
  width: 100%;
  height: 200px;
}

.image-wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
  transition: background 0.3s ease;
}

.portfolio-card:hover .image-wrapper::before {
  background: rgba(0, 0, 0, 0);
}

.portfolio-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.portfolio-card:hover img {
  transform: scale(1.05);
}

.portfolio-card p {
  padding: 1rem;
  font-size: 0.95rem;
  font-weight: 500;
  color: #333;
}

.lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.lightbox-content {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  max-width: 90%;
  max-height: 90%;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.lightbox-content img {
  max-width: 100%;
  max-height: 70vh;
  border-radius: 8px;
}

.lightbox-content p {
  margin-top: 1rem;
  font-size: 1.1rem;
  font-weight: 600;
}

.lightbox-content button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background: #0b0b3b;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
} 

@media (max-width: 768px) {
  .portfolio-section {
    padding: 2rem 1rem;
  }

  .portfolio-categories {
    width: 100%;
    max-width: 100%;
    margin: 0 auto 2rem;
    border-radius: 16px;
    padding: 1rem;
    box-sizing: border-box;
  }

  .category-btn {
    font-size: 1rem;
    text-align: left;
    width: 100%;
  }

  .portfolio-gallery {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .portfolio-card p {
    font-size: 1rem;
  }

  .portfolio-categories {
    display: flex;
    flex-direction: column;
    gap: 0.75rem; /* <-- add spacing between category buttons */
  }

  .category-btn {
    font-size: 1rem;
    text-align: left;
    width: 100%;
    padding: 0.5rem 0; /* Optional: adds touch-friendly tap area */
  }
}

